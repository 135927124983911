import { Tooltip } from '@gbm/queen-ui-guidelines';
import { CheckmarkCircleIcon, NoInfoIcon } from 'components/Icons';
import { CheckmarkXcircleIcon } from 'components/Icons/CheckmarkXcircleIcon';
import { MinusCircleIcon } from 'components/Icons/MinusCircleIcon';
import { TransactionAnalysisMatchStatus } from 'constants/transactionAnalysisStatus';
import { FC } from 'react';

type IconTxResultProps = { matchStatus: string };
export const IconTxResult: FC<IconTxResultProps> = ({ matchStatus }) => {
  switch (matchStatus) {
    case TransactionAnalysisMatchStatus.rulePassed:
      return (
        <Tooltip position="top" message="Rule under compliance">
          <CheckmarkCircleIcon color={'success'} />
        </Tooltip>
      );

    case TransactionAnalysisMatchStatus.ruleBreaking:
      return (
        <Tooltip position="top" message="Rule breaking.">
          <CheckmarkXcircleIcon color="error" />
        </Tooltip>
      );

    case TransactionAnalysisMatchStatus.emptyParam:
      return (
        <Tooltip position="top" message="Analysis not performed.">
          <MinusCircleIcon color="info" />
        </Tooltip>
      );

    case TransactionAnalysisMatchStatus.noThreshold:
      return (
        <Tooltip position="top" message="Missing information.">
          <NoInfoIcon color="info" />
        </Tooltip>
      );

    default:
      return (
        <Tooltip position="top" message="Analysis execution error.">
          <CheckmarkXcircleIcon color="warning" />
        </Tooltip>
      );
  }
};
