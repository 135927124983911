import clsx from 'clsx';
import { FC } from 'react';
import { RiInformationOffFill } from 'react-icons/ri';
import styles from './styles.module.scss';

type NoInfoIconProps = { color: string };

export const NoInfoIcon: FC<NoInfoIconProps> = ({ color }) => (
  <RiInformationOffFill className={clsx(styles.checkIcon, styles[color])} />
);
