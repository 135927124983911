import clsx from 'clsx';
import { FC } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import styles from './styles.module.scss';

type CheckmarkCircleIconProps = { color: string };

export const CheckmarkCircleIcon: FC<CheckmarkCircleIconProps> = ({
  color,
}) => <AiFillCheckCircle className={clsx(styles.checkIcon, styles[color])} />;
