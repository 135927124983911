import { FC, useState } from 'react';
import {
  TransactionalAnalysisData,
  ValidatorManualReview,
} from 'types/Transaction';
import { Margin, Grid, Badge, Notification } from '@gbm/queen-ui-guidelines';
import ModalDetailData from '../ModalDetailData';
import styles from './styles.module.scss';
import TransactionalInformationTxAnalysis from './TransactionInformationTxAnalysis';
import TransactionalResultTxAnalysis from './TransactionResultTxAnalysis';
import ButtonsValidation from 'components/ButtonsValidation';
import { useDispatcherTransactions } from 'providers/Transactions/useDispatcher';
import ModalVerification from '../ModalVerification';
import {
  getColorForBadge,
  getStatusToDisplay,
  TransactionTypes,
} from 'utils/transactionsUtils';
import { TransactionStatus } from 'constants/transactionStatus';
import { TransactionValidationToSave } from 'types/TransactionValidationToSave';
import ModalDetailVerification from '../ModalDetailVerification';
import ModalTransactionResultMoreInfo from './ModalTransactionResultMoreInfo';

type TransactionalAnalysisValidatorProps = {
  transactionalAnalysisData: TransactionalAnalysisData | null;
  updateValidationsToFill: Function;
  isSelectedValidatorFilled: (selectedTransactionId: string) => boolean;
  transactionStatus: string | undefined;
};
const TransactionalAnalysisValidator: FC<
  TransactionalAnalysisValidatorProps
> = ({
  transactionalAnalysisData,
  updateValidationsToFill,
  isSelectedValidatorFilled,
  transactionStatus,
}) => {
  const [isOpenDetailModalData, setIsOpenDetailModalData] =
    useState<boolean>(false);
  const [isOpenModalVerification, setIsOpenModalVerification] = useState(false);
  const [isOpenSuccessMessage, setIsOpenSuccessMessage] =
    useState<boolean>(false);
  const [extraInfo, setExtraInfo] = useState<any>();
  const { addTransactionToSave } = useDispatcherTransactions();
  const handleOpenModalDetailData = (extraData: any) => {
    setExtraInfo(extraData);
    setIsOpenDetailModalData(true);
  };
  const [isOpenModalDetailVerification, setIsOpenModalDetailVerification] =
    useState<boolean>(false);
  const [
    isOpenModalTransactionResultMoreInfo,
    setIsOpenModalTransactionResultMoreInfo,
  ] = useState<boolean>(false);

  const handleAddVerification = (dataValidation: any) => {
    const data: ValidatorManualReview = {
      code: TransactionTypes.TXN,
      status: dataValidation?.dropdownStatus?.id,
      details: {
        comments: dataValidation?.comment,
        complementaryComments: dataValidation?.supplementaryComment,
      },
      requestId: transactionalAnalysisData?.response?.requestId,
      id: '',
    };
    addTransactionToSave({
      validator: TransactionTypes.TXN,
      data: { ...data },
    });
    updateValidationsToFill(
      (validationToFill: TransactionValidationToSave[]) => {
        const validationToFillCopy = [...validationToFill];

        const indexOfElement = validationToFillCopy.findIndex(
          (validation: TransactionValidationToSave) =>
            validation.id === transactionalAnalysisData?.response?.requestId,
        );
        if (indexOfElement !== -1) {
          validationToFillCopy[indexOfElement].completed = true;
        }
        return validationToFillCopy;
      },
    );
    setIsOpenModalVerification(false);
    setIsOpenSuccessMessage(true);
    setTimeout(() => {
      setIsOpenSuccessMessage(false);
    }, 3000);
  };

  const disableAddValidationButton = () => {
    return !(
      transactionalAnalysisData?.response.matchAction ===
        TransactionStatus.manualReview &&
      !isSelectedValidatorFilled(
        transactionalAnalysisData?.response.requestId ?? '',
      ) &&
      (transactionStatus === TransactionStatus.manualReview ||
        transactionStatus === TransactionStatus.underReview)
    );
  };

  return (
    <>
      <Grid>
        <Margin side="bottom" xs={24}>
          <Margin side="bottom" xs={24}>
            <div className="d-flex justify-content-between align-items-center w-full">
              <Badge
                className={styles.badge}
                color={getColorForBadge(
                  transactionalAnalysisData?.response.matchAction ?? '',
                )}
                variant="pill"
              >
                {getStatusToDisplay(
                  transactionalAnalysisData?.response.matchAction,
                )}
              </Badge>
              <ButtonsValidation
                onAddValidation={() => setIsOpenModalVerification(true)}
                onSeeValidation={() => setIsOpenModalDetailVerification(true)}
                isDisabledSeeValidationAction={
                  !transactionalAnalysisData?.manualReview
                }
                isDisabledAddValidationAction={disableAddValidationButton()}
              />
            </div>
          </Margin>
          <Margin side="bottom" xs={24}>
            <Grid row>
              <Grid sm={12} xl={6}>
                <TransactionalInformationTxAnalysis
                  selectedTransactionalAnalysisData={transactionalAnalysisData}
                  handleOpenModalDetailData={handleOpenModalDetailData}
                />
              </Grid>
              <Grid sm={12} xl={6}>
                <TransactionalResultTxAnalysis
                  selectedTransactionalAnalysisData={transactionalAnalysisData}
                  handleOpenModalDetailData={() =>
                    setIsOpenModalTransactionResultMoreInfo(true)
                  }
                />
              </Grid>
            </Grid>
          </Margin>
        </Margin>
      </Grid>
      <ModalDetailData
        onClose={() => setIsOpenDetailModalData(false)}
        isOpen={isOpenDetailModalData}
        extraInfo={extraInfo}
      />
      <ModalVerification
        onClose={() => {
          setIsOpenModalVerification(false);
        }}
        onSubmit={handleAddVerification}
        isOpen={isOpenModalVerification}
      />
      <ModalDetailVerification
        onClose={() => setIsOpenModalDetailVerification(false)}
        isOpen={isOpenModalDetailVerification}
        user={transactionalAnalysisData?.userManualReview}
        manualReview={transactionalAnalysisData?.manualReview}
      />
      <ModalTransactionResultMoreInfo
        onClose={() => setIsOpenModalTransactionResultMoreInfo(false)}
        isOpen={isOpenModalTransactionResultMoreInfo}
        transactionalAnalysisResponse={transactionalAnalysisData?.response}
      />
      <Notification open={isOpenSuccessMessage} color="success" colorInherit>
        The manual review has been added successfully
      </Notification>
    </>
  );
};

export default TransactionalAnalysisValidator;
