import {
  Typography,
  Badge,
  CopyToClipboard,
  Tooltip,
  SkeletonLoader,
  Skeleton,
  Button,
  Grid,
} from '@gbm/queen-ui-guidelines';
import Feature from 'providers/FeatureProvider/Feature';
import { FC } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { formatDateTimeTZUser } from 'utils/dates';
import getDisplayId from 'utils/getDisplayId';
import { getColorForBadge, getStatusToDisplay } from 'utils/transactionsUtils';
import { TbStatusChange } from 'react-icons/tb';
import { MdCancel } from 'react-icons/md';
import styles from './styles.module.scss';
import { TransactionStatus } from 'constants/transactionStatus';

type HeaderProps = {
  id: string | undefined;
  status: string | undefined;
  loading: boolean;
  setStatusToTransaction: () => void;
  cancelTransaction: () => void;
  createdAt: string | undefined;
  updatedAt: string | undefined;
};

const Header: FC<HeaderProps> = ({
  id,
  status,
  loading,
  setStatusToTransaction,
  cancelTransaction,
  createdAt,
  updatedAt,
}) => (
  <>
    <header className={styles.header}>
      {loading ? (
        <Grid row justify="center">
          <Grid md={6}>
            <div className={styles.loaderContainer}>
              <SkeletonLoader>
                <Skeleton height={40} width="25%" />
                <Skeleton height={20} width={100} />
              </SkeletonLoader>
            </div>
          </Grid>
          <Grid md={6}>
            <div className={styles.loaderContainerRow}>
              <SkeletonLoader>
                <Skeleton height={45} width="40%" />
                <Skeleton height={45} width="40%" />
              </SkeletonLoader>
            </div>
          </Grid>
        </Grid>
      ) : (
        <>
          <div className={styles.txHeaderLeft}>
            <div className={styles.txIdContainer}>
              <Typography
                className={styles.title}
                variant="headerTitle"
                weight="bold"
              >
                Transaction:
              </Typography>
              <Typography
                variant="headerTitle"
                weight="bold"
                className={styles.title}
              >
                <CopyToClipboard
                  messageBeforeCopy="Copiar"
                  messageAfterCopy="¡Copiado!"
                  value={id}
                >
                  {getDisplayId(id || '')}
                </CopyToClipboard>
              </Typography>
            </div>
            <div className={styles.txInfoContainer}>
              <Badge
                className={styles.badge}
                color={getColorForBadge(status ?? '')}
                variant="pill"
              >
                {getStatusToDisplay(status)}
              </Badge>
              <Tooltip
                position="bottom"
                message={`Created at: ${formatDateTimeTZUser(
                  createdAt ?? '',
                )} Updated at: ${formatDateTimeTZUser(updatedAt ?? '')}`}
              >
                <FaInfoCircle className={styles.infoIcon} />
              </Tooltip>
            </div>
          </div>
          <div className={styles.actionButtons}>
            <Feature name="transactionStatus">
              <Button
                color="light"
                onClick={() => {
                  setStatusToTransaction();
                }}
                disabled={
                  status === TransactionStatus.canceled ||
                  status === TransactionStatus.accepted ||
                  status === TransactionStatus.rejected ||
                  status === TransactionStatus.underReview
                }
              >
                <TbStatusChange /> Set on Review
              </Button>
            </Feature>
            <Feature name="transactionCancel">
              <Button
                color="danger"
                onClick={() => {
                  cancelTransaction();
                }}
                disabled={
                  status === TransactionStatus.canceled ||
                  status === TransactionStatus.accepted ||
                  status === TransactionStatus.rejected
                }
              >
                <MdCancel /> Cancel
              </Button>
            </Feature>
          </div>
        </>
      )}
    </header>
  </>
);

export default Header;
