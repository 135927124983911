export const TransactionAnalysisStatus = {
  ok: 'OK',
  notExecuted: 'NOT_EXECUTED',
  error: 'ERROR',
};

export const TransactionAnalysisMatchStatus = {
  rulePassed: 'RULE_PASSED',
  ruleBreaking: 'RULE_BREAKING',
  emptyParam: 'NA',
  noThreshold: 'NO_THRESHOLD',
};
