export default class CustomError extends Error {
  response: Response | undefined;

  status: number | undefined;

  code: number | undefined;

  id: string | undefined;

  requestId: string | undefined | null;

  notifications: string | undefined;
}
