import { ImBlocked } from 'react-icons/im';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { FC } from 'react';

type CheckmarkIconProps = { color: string };

export const BlockIcon: FC<CheckmarkIconProps> = ({ color }) => (
  <ImBlocked className={clsx(styles.xMarkIcon, styles[color])} />
);
