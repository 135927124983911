import { FC, useEffect, useState } from 'react';
import { Margin, Grid, Badge, Notification } from '@gbm/queen-ui-guidelines';
import DropdownController from 'components/common/DropdownController';
import { DropdownOptions } from 'types/DropdownOptions';
import { CountryData, ValidatorManualReview } from 'types/Transaction';
import {
  getColorForBadge,
  getStatusToDisplay,
  TransactionTypes,
} from 'utils/transactionsUtils';
import TransactionInformationCountries from './TransactionInformationCountries';
import ModalDetailData from '../ModalDetailData';
import ButtonsValidation from 'components/ButtonsValidation';
import TransactionResultCountries from './TransactionResultCountries';
import ModalVerification from '../ModalVerification';
import { useDispatcherTransactions } from 'providers/Transactions/useDispatcher';
import styles from './styles.module.scss';
import { TransactionStatus } from 'constants/transactionStatus';
import { TransactionValidationToSave } from 'types/TransactionValidationToSave';
import ModalDetailVerification from '../ModalDetailVerification';
import startCase from 'lodash/startCase';
type CountriesValidatorProps = {
  countriesData?: CountryData[] | null;
  updateValidationsToFill: Function;
  isSelectedValidatorFilled: (selectedTransactionId: string) => boolean;
  transactionStatus: string | undefined;
};
const CountriesValidator: FC<CountriesValidatorProps> = ({
  countriesData,
  updateValidationsToFill,
  isSelectedValidatorFilled,
  transactionStatus,
}) => {
  const [isOpenDetailModalData, setIsOpenDetailModalData] =
    useState<boolean>(false);
  const [isOpenModalVerification, setIsOpenModalVerification] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<CountryData>();
  const [optionsCountries, setOptionsCountries] = useState<DropdownOptions[]>();
  const [selectedOptionCountries, setSelectedOptionCountries] =
    useState<DropdownOptions | null>(null);
  const { addTransactionToSave } = useDispatcherTransactions();
  const [isOpenSuccessMessage, setIsOpenSuccessMessage] =
    useState<boolean>(false);
  const [isOpenModalDetailVerification, setIsOpenModalDetailVerification] =
    useState<boolean>(false);
  const [extraInfo, setExtraInfo] = useState<any>();
  useEffect(() => {
    if (selectedOptionCountries) {
      const country = countriesData?.find(
        (country: CountryData) =>
          selectedOptionCountries?.id === country.frontendId,
      );

      if (country) {
        setSelectedCountry(country);
      }
    }
  }, [selectedOptionCountries]);

  useEffect(() => {
    if (countriesData) {
      const options = countriesData.map((country, i) => ({
        label: `${startCase(country?.type)} - ${getStatusToDisplay(
          country?.validator.matchAction,
        )}`,
        displayName: `${startCase(country?.type)} - ${getStatusToDisplay(
          country?.validator.matchAction,
        )}`,
        name: `${country?.code}-${country?.type}-${country?.validator.matchAction}`,
        value: `${country?.frontendId}`,
        id: `${country?.frontendId}`,
      }));
      setOptionsCountries(options);
      setSelectedCountry(countriesData[0]);
      setSelectedOptionCountries(options[0]);
    }
  }, [countriesData]);

  const handleOpenModalDetailData = (extraData: any) => {
    setExtraInfo(extraData);
    setIsOpenDetailModalData(true);
  };

  const handleAddVerification = (dataValidation: any) => {
    const data: ValidatorManualReview = {
      code: TransactionTypes.COUNTRIES,
      status: dataValidation?.dropdownStatus?.id,
      details: {
        comments: dataValidation?.comment,
        complementaryComments: dataValidation?.supplementaryComment,
      },
      requestId: selectedCountry?.validator?.requestId,
      labelToShow: `${startCase(
        selectedCountry?.type ?? '',
      )} - ${getStatusToDisplay(selectedCountry?.validator.matchAction)}`,
      id: '',
    };
    addTransactionToSave({
      validator: TransactionTypes.COUNTRIES,
      data: { ...data },
    });
    updateValidationsToFill(
      (validationToFill: TransactionValidationToSave[]) => {
        const validationToFillCopy = [...validationToFill];

        const indexOfElement = validationToFillCopy.findIndex(
          (validation: TransactionValidationToSave) =>
            validation.id === selectedCountry?.validator?.requestId,
        );
        if (indexOfElement !== -1) {
          validationToFillCopy[indexOfElement].completed = true;
        }
        return validationToFillCopy;
      },
    );
    setIsOpenModalVerification(false);
    setIsOpenSuccessMessage(true);
    setTimeout(() => {
      setIsOpenSuccessMessage(false);
    }, 3000);
  };

  const disableAddValidationButton = () => {
    return !(
      selectedCountry?.validator.matchAction ===
        TransactionStatus.manualReview &&
      !isSelectedValidatorFilled(selectedCountry?.validator.requestId ?? '') &&
      (transactionStatus === TransactionStatus.manualReview ||
        transactionStatus === TransactionStatus.underReview)
    );
  };

  return (
    <>
      <Grid>
        <Margin side="bottom" xs={24}>
          <Margin side="bottom" xs={24}>
            <div className="d-flex justify-content-between align-items-center w-full">
              <div>
                <DropdownController
                  id="selectCountriesValidator"
                  name="selectCountriesValidator"
                  label="Countries validations"
                  placeholder={
                    selectedOptionCountries
                      ? selectedOptionCountries?.displayName ?? ''
                      : 'Select country'
                  }
                  options={optionsCountries}
                  required={false}
                  onChange={setSelectedOptionCountries}
                />
                <Badge
                  className={styles.badge}
                  color={getColorForBadge(
                    selectedCountry?.validator.matchAction ?? '',
                  )}
                  variant="pill"
                >
                  {getStatusToDisplay(selectedCountry?.validator.matchAction)}
                </Badge>
              </div>

              <ButtonsValidation
                onAddValidation={() => setIsOpenModalVerification(true)}
                onSeeValidation={() => setIsOpenModalDetailVerification(true)}
                isDisabledSeeValidationAction={!selectedCountry?.manualReview}
                isDisabledAddValidationAction={disableAddValidationButton()}
              />
            </div>
          </Margin>
          <Margin side="bottom" xs={24}>
            <Grid row>
              <Grid sm={12} xl={6}>
                <TransactionInformationCountries
                  selectedCountryData={selectedCountry}
                  handleOpenModalDetailData={handleOpenModalDetailData}
                />
              </Grid>
              <Grid sm={12} xl={6}>
                <TransactionResultCountries
                  selectedCountryData={selectedCountry}
                  handleOpenModalDetailData={handleOpenModalDetailData}
                />
              </Grid>
            </Grid>
          </Margin>
        </Margin>
      </Grid>
      <ModalDetailData
        onClose={() => setIsOpenDetailModalData(false)}
        extraInfo={extraInfo}
        isOpen={isOpenDetailModalData}
      />
      <ModalVerification
        onClose={() => {
          setIsOpenModalVerification(false);
        }}
        onSubmit={handleAddVerification}
        isOpen={isOpenModalVerification}
      />
      <ModalDetailVerification
        onClose={() => setIsOpenModalDetailVerification(false)}
        isOpen={isOpenModalDetailVerification}
        user={selectedCountry?.userManualReview}
        manualReview={selectedCountry?.manualReview}
      />
      <Notification open={isOpenSuccessMessage} color="success" colorInherit>
        The manual review has been added successfully
      </Notification>
    </>
  );
};

export default CountriesValidator;
