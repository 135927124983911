import { FC } from 'react';
import {
  Analysis,
  AnalysisDays,
  DetailMatchTransactionalAnalysis,
} from 'types/Transaction';
import { Typography, Grid, Margin } from '@gbm/queen-ui-guidelines';
import { nanoid } from 'nanoid';
import styles from './styles.module.scss';
import { IconTxResult } from 'components/IconTxResult';

type SummaryAlertsProps = {
  matchDetail: DetailMatchTransactionalAnalysis;
};
const SummaryAlerts: FC<SummaryAlertsProps> = ({ matchDetail }) => {
  return (
    <>
      <Margin side="bottom" xs={24}>
        <Typography component="strong" variant="header">
          Summary Analysis
        </Typography>
      </Margin>

      <Grid row justify="center">
        <Grid md={3}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Typography variant="subHeader">Override</Typography>
            <IconTxResult
              matchStatus={matchDetail.summaryAnalysis.override}
              key={nanoid()}
            />
          </div>
        </Grid>
        <Grid md={2}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Typography variant="subHeader">Beneficiary</Typography>
            <IconTxResult
              matchStatus={matchDetail.summaryAnalysis.days}
              key={nanoid()}
            />
          </div>
        </Grid>
        <Grid md={2}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Typography variant="subHeader">W0</Typography>
            <IconTxResult
              matchStatus={matchDetail.summaryAnalysis.w0}
              key={nanoid()}
            />
          </div>
        </Grid>
        <Grid md={2}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Typography variant="subHeader">W1</Typography>
            <IconTxResult
              matchStatus={matchDetail.summaryAnalysis.w1}
              key={nanoid()}
            />
          </div>
        </Grid>
        <Grid md={3}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Typography variant="subHeader">W2</Typography>
            <IconTxResult
              matchStatus={matchDetail.summaryAnalysis.w2}
              key={nanoid()}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SummaryAlerts;
